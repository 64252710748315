import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../hooks/useWindowSize';

const ContinueButton = ({ isValid, onConfirm, isLastQst = false }) => {
    const { t } = useTranslation();
    const { width, height } = useWindowSize();

    const isMobile = width <= 780;

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter' && isValid) {
                onConfirm();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [isValid, onConfirm]);

    return (
        <button
            className={`bg-[#1e4690] text-white px-12 ${isLastQst || isMobile ? 'py-3' : 'py-1'} hover:bg-[#1e469094] transition rounded-full duration-300 ${!isValid && 'opacity-25'}`}
            disabled={!isValid}
            onClick={onConfirm}
        >
            {isLastQst ? (
                t('revenu.button_save')
            ) : (
                <>
                    {t('siret_page.button')}
                    {isMobile === false && <span className="block">(Enter ↵)</span>}
                </>
            )}
        </button>
    );
};

export default ContinueButton;