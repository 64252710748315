import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { getEnvConfig } from "../../utils/config";
import { Loader } from "../Loader";

// Charge Stripe avec ta clé publique
const stripePromise = loadStripe("pk_test_51PuH4IFogUVbkwou1z1Lp1t2Zu4jdzkvnsLBmAapXlvfNJVSeaErHBDb0a6xZ76JqBMbWuJAYc1OqvDoY5MMzPR500hsOt7fH3");

const DownloadButton = ({ result, setActiveTabLeft }) => {

    const handleDownload = async () => {
        // Stocker `result` dans le localStorage avant redirection
        setIsLoading(true);
        try {
            localStorage.setItem("result", JSON.stringify(result)); // `result` est l'objet que tu veux préserver

            const stripe = await stripePromise;
            const { apiUrl } = getEnvConfig();
            console.log("apiUrl: ", apiUrl + 'run_simulator')
            const response = await fetch(apiUrl + "create-checkout-session", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    amount: 3900, // 9
                    result: result
                }),
            });

            const session = await response.json();

            // Rediriger vers la page de paiement Stripe
            const resultStripe = await stripe.redirectToCheckout({ sessionId: session.id });

            if (resultStripe.error) {
                console.error(resultStripe.error.message);
            }
        } catch (error) {
            console.error('There was a problem with the request:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    return (
        <div>
            {isLoading && <Loader />}

            {/* Conteneur global centré en colonne */}
            <div className="flex flex-col items-center mt-4">
                {/* Bouton d'achat */}
                <button
                    className="bg-[#161C67] text-white px-8 py-2 rounded-full"
                    onClick={handleDownload}
                >
                    <p className="text-base my-1">
                        Télécharger maintenant pour <b className="font-bold">39 €</b>
                    </p>
                </button>

                {/* Séparateur OU */}
                <p className="mt-2 mb-2 text-[#006acc]">OU</p>

                {/* Lien pour obtenir gratuitement sous condition */}
                <button
                    className="text-base mb-1 text-[#006acc] italic"
                    onClick={() => setActiveTabLeft("free")}
                >
                    Obtenir gratuitement sous condition
                </button>
            </div>
        </div>
    );

};

export default DownloadButton;
