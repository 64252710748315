import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import ContinueButton from './ContinueButton';


const CriteresDesignationDPO = ({ nbStep, isComanyCommercial, nbCandtionMicroValide, isSCI, criteresDesignationDPO, setCriteresDesignationDPO, moveToStep, goBack, raisonSociale, isMicroEntreprise }) => {
  const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
  const rangeRef = useRef(null);
  const { t, i18n } = useTranslation();


  const handleConfirm = () => {
    if (isComanyCommercial) {
      moveToStep('DetentionSiteInternet');
    } else {
      moveToStep('eprSelection');
    }
  };

  useEffect(() => {
    updateProgressColor(nbStep, maxValueQuestion, rangeRef);
  }, []);


  return (
    <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
      <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


      <div className='max-w-[800px] mb-1'>

        <h1 className='text-2xl text-center text-[#1e4690] my-6'>
          {i18n.language === 'fr' &&
            <>
              <p className="text-left mb-2">Les activités de base de {raisonSociale} l'amène :</p>
              <ul className="list-disc pl-6 text-left">
                <li>à réaliser un suivi régulier et systématique à grande échelle de personnes physiques, ou</li>
                <li>à traiter à grande échelle des données dites "sensibles" ou relatives à des condamnations pénales et infractions ?</li>
              </ul>
            </>
          }

          {i18n.language === 'en' &&
            <>

              <p className="text-left mb-2">The core activities of {raisonSociale} lead it :</p>
              <ul className="list-disc pl-6 text-left">
                <li>to carry out regular and systematic large-scale monitoring of individuals, or</li>
                <li>large-scale processing of \"sensitive\" data or data relating to criminal convictions and offences</li>
              </ul>
            </>
          }

        </h1>
      </div>

      <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
        <div className="flex flex-col gap-4">
          <button
            className={`w-full p-4 text-center font-bold rounded-xl ${criteresDesignationDPO === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc]'}`}
            onClick={() => setCriteresDesignationDPO(true)}
          >
            {i18n.language === 'fr' ? 'Oui' : "Yes"}
          </button>
          <button
            className={`w-full p-4 text-center font-bold rounded-xl ${criteresDesignationDPO === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc]'}`}
            onClick={() => setCriteresDesignationDPO(false)}
          >
            {i18n.language === 'fr' ? 'Non' : "No"}
          </button>
        </div>
      </div>

      <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
        <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
          {t('revenu.button_back')}
        </button>
        <ContinueButton
          isValid={(criteresDesignationDPO !== null)}
          onConfirm={handleConfirm}
        />
      </div>
    </div>
  );
};

export default CriteresDesignationDPO;
